.select_itemsWrapper {
    padding: 4px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
}
.select_item {
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 4px;
  }