:root {
    --output: rgb(76, 175, 80);
    --box-shadow: rgb(59 178 85 / 38%) 0px 4px 10px;
    --primary: rgb(85, 155, 251);
    --color-text: #FFFFFF;
    --box-shadow: 1px 4px 11px 2px #4caaf73b;
    --input: #1C7ED6;
    --output-top: #1C7ED6;
    --output-bottom: #F03E3E;
    --variable-button: #F99417;
    --source: #828283;
    --comment: #ffc0de;
}

* {
    margin: 0;
    padding: 0;
}

body {
    box-sizing: border-box;
    font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;;
}

.main {
    display: flex;
}

.content {
    height: 100vh;
    width: 100%;
}

.menu-item-text {
    color: #6e82a5;
    font-family: Nunito, sans-serif;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.01em;
    text-transform: none;
    line-height: 1.25rem;
}

.sub-menu-item-text {
    font-size: 14px;
}

aside {
    max-height: 100vh;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: rgb(184, 181, 181);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.react-flow__handle {
    width: 10px !important;
    height: 10px !important;
}

.mantine-qb3vl4 {
    width: auto;
    min-width: 440px;
}

.custom-file-input {
    color: transparent;
    width: 38px;
    height: 36px;
    margin-left: 10px;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
  }
  .custom-wrapper {
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .block-input {
    flex: 1 1 0%; 
  }
  .dropdown {
    position: absolute;
    z-index: 100;
    width: 88%;
    left: 36px;
    margin-top: 5px;
    color: rgb(33, 37, 41);
    background-color:  #fff;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 1px 6px 1px rgb(54 74 99 / 10%);
  }
  .mantine-Popover-dropdown {
    list-style-type: none;
    cursor: pointer;
    padding: 0px;
  }
  .mantine-Popover-dropdown > li {
    padding: 10px 16px;
  }
  /* .mantine-Popover-dropdown > li:hover {
    background-color: #ced4da;
  } */
  .dropdown > ul {
    list-style-type: none;
  }
  .dropdown > ul > li {
    padding: 10px 16px;
  }
  .dropdown > ul > li:hover {
    background-color: #ced4da;
  }
  .w-94 {
    width: 94% !important;
  }
  .w-88 {
    width: 88% !important;
    left: 36px !important;
}
.custom-wrapper .dropdown {
  width: 81% !important;
  left: 36px !important;
}
.insertVariableButton button {
  -webkit-tap-highlight-color: transparent;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  cursor: pointer;
  border: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: 16px;
  background-color: transparent;
  text-align: left;
  color: #000;
  -webkit-text-decoration: none;
  text-decoration: none;
  box-sizing: border-box;
  border: 1px solid transparent;
  background-color: #fab005;
  color: #fff;
  position: relative;
  height: 36px;
  min-height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 4px;
  padding: 0;
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.react-flow__edge.selected .react-flow__edge-path, .react-flow__edge:focus .react-flow__edge-path, .react-flow__edge:focus-visible .react-flow__edge-path {
  stroke: yellow !important;
}

.select-item {
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  color: #000;
  border-radius: 4px;
}
.select-item-dark-mode {
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  color: #C1C2C5;
  border-radius: 4px;
}