.mantine-Modal-body {
  overflow: auto;
  transition: color .3s ease;
}
.mantine-Modal-body::-webkit-scrollbar {
  color: rgba(0, 0, 0, 0);
  width: 10px;
  height: 10px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}
.mantine-Modal-body::-webkit-scrollbar-corner {
  background: transparent;
}
.mantine-Modal-body::-webkit-scrollbar-thumb {
  width: 10px;
  height: 30px;
  border-radius: 13px;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 10px #d5dce9;
  border: 10px solid transparent;
  background: #d5dce9 !important;
}
.mantine-Modal-body:hover::-webkit-scrollbar-thumb {
  border-radius: 13px;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 10px;
  border: 10px solid transparent;
}